<template>
	<div>
		<div class="header">
			<button class="icon" @click="handleClose">
				<i class="ic-24 ic-close"></i>
			</button>
		</div>

		<div class="contents-wrap">
			<div class="popup-graphic">
				<i class="ic-64 ic-lock"></i>
			</div>
			<div class="text-center">
				<p class="heading-3-b text-center mb-24">{{ loginId }} 님</p>
				<p class="body-2-r text-center mb-24">
					이메일 인증이 완료되지 않았습니다. <br />
					아래 이메일 입력을 통해 인증 메일을 확인해주세요. <br />
					이메일 인증 불가시 1577-3019 문의 바랍니다.
				</p>
				<p v-if="isReSent" class="body-2-r">
					<em class="point"
						>인증 메일을 재전송했습니다.<br />
						인증 메일은 발송 시점으로부터 1시간 동안 유효합니다.</em
					>
				</p>
				<br />
				<div class="group-inp block" :class="{ invalid: isInvalidEmail }">
					<input
						v-model="targetEmail"
						v-on:input="validateEmail"
						v-on:blur="validateDuplicateEmail"
						ref="email"
						type="email"
						id="email"
						maxlength="50"
						value=""
						:placeholder="targetEmail"
					/>
					<p v-if="emailReason" class="text-detail" :class="[isInvalidEmail ? 'invalid' : 'valid']">
						<i class="ic-16" :class="[isInvalidEmail ? 'ic-invalid' : 'ic-valid']"></i>{{ emailReason }}
					</p>
				</div>
			</div>
		</div>
		<div class="btn-area group-btn">
			<button class="line" @click="handleClose">취소</button>
			<button class="solid" @click="resendMail">인증 메일 다시 보내기</button>
		</div>
	</div>
</template>

<script>
import { retryEmail, duplicationEmail } from '@/api/login';
import { VALID_EMAIL, INVALID_EMAIL, SEND_EMAIL_WAITING, ALREADY_USED_EMAIL } from '@/temlplates';
import mixinsAlert from '@/components/common/mixinsAlert';
import _ from 'lodash';
import { checkEmail } from '@/plugins/custom/modules/validator';

export default {
	name: 'BeforeLogin',
	props: {
		email: String,
		loginId: String,
	},
	data() {
		return {
			isReSent: false,
			waitOneMinute: false,
			targetEmail: this.email,
			isInvalidEmail: null,
			emailReason: null,
		};
	},
	mixins: [mixinsAlert],
	methods: {
		handleClose() {
			this.$emit('close');
		},
		validateEmail() {
			if (checkEmail(this.targetEmail)) {
				this.isInvalidEmail = false;
				this.emailReason = VALID_EMAIL;
			} else {
				this.isInvalidEmail = true;
				this.emailReason = INVALID_EMAIL;
			}
		},
		async validateDuplicateEmail() {
			if (this.targetEmail === this.email) {
				this.isInvalidEmail = false;
				return;
			}

			if (_.isEmpty(this.targetEmail)) {
				return;
			}
			let response = await duplicationEmail(this.targetEmail);
			if (response.data) {
				this.isInvalidEmail = true;
				this.emailReason = ALREADY_USED_EMAIL;
			}
		},
		async resendMail() {
			if (this.isInvalidEmail) return;

			if (!this.waitOneMinute) {
				const data = {
					loginId: this.loginId,
					email: this.targetEmail,
				};

				try {
					this.waitOneMinute = true;
					const response = await retryEmail(data);
					this.$toasted.show('인증 메일을 전송했습니다.');
					this.isReSent = true;
					setTimeout(this.clearTimer, 60000);
				} catch (e) {
					if (e.data.errorCode === 'ERROR208') {
						this.$emit('close');
						this.$toasted.show('이미 회원가입 메일 인증을 시도했습니다. 로그인해주세요.');
					} else {
						this.$toasted.show('인증 메일 전송에 실패했습니다.');
					}
				}
			} else {
				this.alert(SEND_EMAIL_WAITING);
			}
		},
		clearTimer() {
			this.waitOneMinute = false;
		},
	},
};
</script>

<style scoped></style>
