<template>
	<div>
		<div class="header">
			<h1 class="heading-3-b" v-if="step === 1">잠금 해제 및 비밀번호 초기화</h1>
			<button class="icon" @click="handleClose">
				<i class="ic-24 ic-close"></i>
			</button>
		</div>

		<div class="contents-wrap">
			<div v-if="step === 0">
				<div class="popup-graphic">
					<i class="ic-64 ic-noti"></i>
				</div>
				<p class="heading-3-b text-center mb-24">계정이 잠김 처리되었습니다.</p>
				<p class="body-2-r text-center">
					로그인 연속 5회 실패하여 계정 잠김 처리되었습니다. <br />
					본인 인증 후 잠금해제 하세요.
				</p>
			</div>
			<div v-if="step === 1">
				<p class="body-2-r mb-24">
					회원정보에 등록한 이메일 주소를 확인해주세요. <br />
					해당 메일로 임시 비밀번호를 전송합니다.
				</p>
				<FindAccountResultComp :accountList="accountList" v-on:check="handleCheckAccount" :type="'unlock'" />
			</div>
		</div>

		<div class="btn-area group-btn" v-if="step === 0">
			<button class="line" @click="handleClose">취소</button>
			<IdentityVerificationButton v-on:complete="handleComplete" />
		</div>
		<div class="btn-area group-btn" v-if="step === 1">
			<button class="line" @click="handleClose">취소</button>
			<button class="solid" @click="handleResetPasswordAndUnlock">잠금 해제 및 비밀번호 초기화</button>
		</div>
	</div>
</template>

<script>
import IdentityVerificationButton from '../login/IdentityVerificationButton.vue';
import { resetPassword } from '../../api/login';
import FindAccountResultComp from './FindAccountResultComp.vue';
import { checkIdentity } from '../login/mixinsFindUser';

export default {
	name: 'unlockAccountLayer',
	components: { FindAccountResultComp, IdentityVerificationButton },
	mixins: [checkIdentity],
	props: {
		targetLoginId: String,
	},
	data() {
		return {
			step: 0,
			accountList: Array,
			selectedAccount: {},
		};
	},
	methods: {
		handleClose() {
			this.$emit('close');
		},
		handleComplete(data) {
			let userData = {
				name: data.name,
				ci: data.ci,
				phoneNumber: data.phoneNumber,
				birthDay: data.birthday,
				loginId: this.targetLoginId,
			};

			this.findUserByIdentityVerification(
				userData,
				this.targetLoginId,
				(resp) => {
					this.accountList = resp.data;
					this.handleNextStep();
				},
				'unlockAccountLayer',
			);
		},
		handleNextStep() {
			this.step++;
		},
		handleCheckAccount(data) {
			this.selectedAccount = data.account;
		},
		async handleResetPasswordAndUnlock() {
			try {
				await resetPassword({
					id: this.selectedAccount.id,
					email: this.selectedAccount.email,
				});

				this.$toasted.show('비밀번호 초기화 메일이 발송되었습니다.');
				this.$emit('close');
			} catch (e) {
				console.log(e);
				if (e.data.errorCode === 'ERROR210') {
					this.$emit('close');
					this.observeErrCode(e.data.errorCode, e.data.errorMessage, this.setLoginErrorMessage);
				} else {
					this.$toasted.show('비밀번호 초기화 메일 발송에 실패했습니다. 다시 시도해주세요');
				}
			}
		},
	},
};
</script>

<style scoped></style>
