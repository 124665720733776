<template>
	<!-- 비밀번호 변경 -->
	<div>
		<!-- header -->
		<div class="header">
			<h1 class="heading-3-b">비밀번호를 변경해주세요.</h1>
		</div>
		<!-- contents-wrap -->
		<div class="contents-wrap">
			<p class="body-2-r mb-24">다우오피스 고객포털의 안전한 사용을 위하여 기존 비밀번호를 변경해야 합니다.</p>

			<div v-if="isInvalidNewPassword" class="message-balloon mb-24">{{ newPasswordReason }}</div>
			<div v-if="isInvalidNewPasswordConfirm" class="message-balloon mb-24">{{ newPasswordConfirmReason }}</div>

			<form>
				<fieldset>
					<!--					<legend class="blind">본인인증</legend>-->

					<ul class="inp-list">
						<li :class="{ invalid: this.isInvalidNewPassword }">
							<div class="group-inp rgt-ic-16">
								<label for="newPassword" class="label">새 비밀번호</label>
								<input
									id="newPassword"
									class=""
									placeholder="비밀번호를 입력하세요."
									ref="newPasswordInput"
									:type="isNewPasswordVisible ? 'text' : 'password'"
									maxlength="50"
									v-model="newPassword"
									v-on:input="validatePassword"
								/>
								<i
									@click="toggleNewPassword"
									class="ic-16"
									:class="{ 'ic-view-off': !isNewPasswordVisible, 'ic-view': isNewPasswordVisible }"
								></i>
							</div>
						</li>
						<li :class="{ invalid: this.isInvalidNewPasswordConfirm }">
							<div class="group-inp rgt-ic-16">
								<label for="newPasswordConfirm" class="label">새 비밀번호 확인</label>
								<input
									id="newPasswordConfirm"
									class=""
									placeholder="비밀번호를 다시한번 입력하세요."
									ref="newPasswordConfirmInput"
									:type="isNewPasswordConfirmVisible ? 'text' : 'password'"
									maxlength="50"
									v-model="newPasswordConfirm"
									v-on:input="validatePasswordConfirm"
								/>
								<i
									@click="toggleNewPasswordConfirm"
									class="ic-16"
									:class="{ 'ic-view-off': !isNewPasswordConfirmVisible, 'ic-view': isNewPasswordConfirmVisible }"
								></i>
							</div>
						</li>
					</ul>
				</fieldset>
			</form>
			<ul class="bullet-list guide-text">
				<li class="">8~16자의 영문자, 숫자, 특수문자를 조합하여 사용하세요.</li>
				<li class="">아이디, 이메일, 생년월일, 전화번호, 연속되는 숫자와 문자 등은 비밀번호 사용하실 수 없습니다.</li>
			</ul>
		</div>
		<!-- btn area  -->
		<div class="btn-area group-btn">
			<button class="solid" @click="handlePasswordChange($event)">변경완료</button>
		</div>
	</div>
</template>

<script>
import { password_validation } from '@/utils/utils';
import {
	INVALID_PASSWORD_CONTENT,
	INVALID_PASSWORD_LENGTH_CHARACTER,
	PASSWORD_MATCH,
	PASSWORD_MATCH_FAIL,
	VALID_PASSWORD,
} from '@/temlplates';
import { mixinLinkAccount } from '@/components/login/mixinsLinkAccount';

export default {
	name: 'ForcedPasswordChange',
	props: {
		loginId: String,
		password: String,
	},
	data() {
		return {
			newPassword: '',
			newPasswordReason: '',
			isInvalidNewPassword: null,
			newPasswordConfirm: '',
			newPasswordConfirmReason: '',
			isInvalidNewPasswordConfirm: null,
			isNewPasswordVisible: false,
			isNewPasswordConfirmVisible: false,
		};
	},
	mixins: [mixinLinkAccount],
	methods: {
		toggleNewPasswordConfirm() {
			this.newPasswordConfirm = this.$refs.newPasswordConfirmInput.value;
			this.isNewPasswordConfirmVisible = !this.isNewPasswordConfirmVisible;
		},
		toggleNewPassword() {
			this.newPassword = this.$refs.newPasswordInput.value;
			this.isNewPasswordVisible = !this.isNewPasswordVisible;
		},
		async handlePasswordChange() {
			if (!this.allValidationCheck()) {
				return;
			}

			let data = {
				loginId: this.loginId,
				oldPassword: this.password,
				newPassword: this.newPassword,
				newPasswordConfirm: this.newPasswordConfirm,
			};

			await this.$store
				.dispatch('loginAfterForcedPasswordChange', data)
				.then(() => {
					this.$modal.hide('forcedPasswordChange');
					this.$store.dispatch('loginConcurrentAccess').then(() => {
						this.linkService().finally(() => this.$router.push('/home'));
					});
				})
				.catch((resp) => {
					this.newPasswordReason = resp.data.errorMessage;
					this.isInvalidNewPassword = true;
				});
		},
		allValidationCheck() {
			if (!this.newPassword || this.isInvalidNewPassword) {
				if (!this.newPassword) {
					this.isInvalidNewPassword = true;
					this.newPasswordReason = INVALID_PASSWORD_LENGTH_CHARACTER;
				}
				this.$refs.newPasswordInput.focus();
			} else if (
				!this.newPasswordConfirm ||
				this.isInvalidNewPasswordConfirm ||
				this.newPassword !== this.newPasswordConfirm
			) {
				if (!this.newPasswordConfirm) {
					this.isInvalidNewPasswordConfirm = true;
					this.newPasswordConfirmReason = PASSWORD_MATCH_FAIL;
				}
				this.$refs.newPasswordConfirmInput.focus();
			}

			return !this.isInvalidNewPassword && !this.isInvalidNewPasswordConfirm;
		},
		validatePassword() {
			if (!password_validation.checkLengthAndCharacter(this.newPassword)) {
				this.isInvalidNewPassword = true;
				this.newPasswordReason = INVALID_PASSWORD_LENGTH_CHARACTER;
				return false;
			} else if (
				!password_validation.checkRepeatedCharacters(this.newPassword) ||
				!password_validation.checkConsecutiveCharacters(this.newPassword)
			) {
				this.isInvalidNewPassword = true;
				this.newPasswordReason = INVALID_PASSWORD_CONTENT;
				return false;
			} else {
				this.isInvalidNewPassword = false;
				this.newPasswordReason = VALID_PASSWORD;
			}

			if (this.newPasswordConfirm.length !== 0) {
				this.validatePasswordConfirm();
			}
		},
		validatePasswordConfirm() {
			if (this.newPassword !== this.newPasswordConfirm) {
				this.isInvalidNewPasswordConfirm = true;
				this.newPasswordConfirmReason = PASSWORD_MATCH_FAIL;
			} else {
				this.isInvalidNewPasswordConfirm = false;
				this.newPasswordConfirmReason = PASSWORD_MATCH;
			}
		},
	},
};
</script>
