<template>
	<ul class="select-list">
		<li v-for="(account, idx) in accountList" :key="account.id">
			<div
				class="group-rdo large"
				@click="checkEvent(account)"
				v-if="accountList.length > 1 || type === 'unlock' || type === 'resetPassword'"
			>
				<input type="radio" :id="idx" name="testSelectOption" :value="account" v-model="selectedAccount" />
				<label :for="account.id" class="text-option">
					<div class="option-list">
						<p class="text-main">{{ account.email }}</p>
					</div>
				</label>
			</div>
			<div v-if="account === selectedAccount && type === 'findAccount'">
				<div class="highlight">
					<div class="mb-16">
						<p class="body-2-r mb-8">아이디</p>
						<p class="subheading-b">{{ account.loginId }}</p>
					</div>
					<div class="mb-16">
						<p class="body-2-r mb-8">이메일</p>
						<p class="subheading-b">{{ account.email }}</p>
					</div>
					<div class="mb-16">
						<p class="body-2-r mb-8">가입일자</p>
						<p class="subheading-b">{{ account.createdAt }}</p>
					</div>
				</div>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'FindAccountResultComp',
	props: {
		accountList: Array,
		type: String,
	},
	data() {
		return {
			selectedAccount: this.accountList[0],
		};
	},
	mounted() {
		this.$emit('check', {
			account: this.selectedAccount,
		});
	},
	methods: {
		checkEvent(account) {
			this.selectedAccount = account;
			this.$emit('check', {
				account: this.selectedAccount,
			});
		},
	},
};
</script>

<style scoped></style>
