import { SIGNUP_MODAL_STYLE } from '@/utils/constants';
import BeforeLogin from '@/components/login/BeforeLogin';
import ForceLogout from '@/components/login/ForceLogoutModal.vue';
import ForcedPasswordChange from '@/components/login/ForcedPasswordChange.vue';
import UnlockAccountLayer from '../Identified/UnlockAccountLayer.vue';
import { OTHER_CUSTOMER_SUPER_ADMIN } from '@/temlplates';
import mixinsAlert from '@/components/common/mixinsAlert';

/**
 * 해당 믹스인에서 불러오는 컴포넌트는 예외처리를 아래 코드를 이용하여 처리할 수 없습니다.
 * (순환참조 발생)
 */
export default {
	mixins: [mixinsAlert],
	methods: {
		observeErrCode(code, param, message) {
			const action = {
				ERROR0001: (error) => {
					message('시스템 에러가 발생하였습니다.');
					return code;
				},
				ERROR0012: (error) => {
					message(param);
					return code;
				},
				ERROR0013: (error) => {
					message(param);
					return code;
				},
				ERROR0014: (error) => {
					message(param);
					return code;
				},
				ERROR0207: (error) => {
					this.$modal.show(
						UnlockAccountLayer,
						{ targetLoginId: this.loginId },
						Object.assign({}, SIGNUP_MODAL_STYLE, { name: 'unlockAccountLayer', clickToClose: false }),
					);
					return code;
				},
				ERROR208: (error) => {
					message(param);
					return code;
				},
				ERROR210: (error) => {
					let email = param.match(/(?<=\(email:)(.*?)(?=\))/)[0];
					this.$modal.show(
						BeforeLogin,
						{ email: email, loginId: this.loginId },
						Object.assign({}, SIGNUP_MODAL_STYLE, { name: 'beforeLogin', clickToClose: false }),
					);
					return code;
				},
				ERROR211: (error) => {
					this.$modal.show(
						ForcedPasswordChange,
						{ loginId: param.loginId, password: param.password },
						Object.assign({}, SIGNUP_MODAL_STYLE, { name: 'forcedPasswordChange', clickToClose: false }),
					);
					return code;
				},
				ERROR0202: (error) => {
					message('존재하지 않는 회원입니다.');
					return code;
				},
				ERROR0010: (error) => {
					message(param);
					return code;
				},
				ERROR0502: (error) => {
					message(param);
					return code;
				},
				ERROR0503: (error) => {
					message(param);
					return code;
				},
				ERROR0504: (error) => {
					message(param);
					return code;
				},
				ERROR0505: (error) => {
					message(param);
					return code;
				},
				ERROR0601: (error) => {
					message(param);
					return code;
				},
				GW_ERR002: (error) => {
					this.$modal.show(
						ForceLogout,
						{},
						Object.assign({}, SIGNUP_MODAL_STYLE, { name: ForceLogout.name, clickToClose: false }),
					);
					return code;
				},
				ERROR0701: (error) => {
					message(param);
					return code;
				},
				ERROR0702: (error) => {
					this.alert({ contents: OTHER_CUSTOMER_SUPER_ADMIN });
					return code;
				},
				ERROR0801: (error) => {
					message(param);
					return code;
				},
			};
			return (
				action[code]?.(param) ??
				(() => {
					console.log('no error match', code, param);
					this.$toasted.show('현재 요청이 처리되지 않습니다. 다우오피스 관리자에 문의바랍니다.');
					return `정의되지 않은 서버 에러 발생. 관리자에게 문의해주세요. ${code}`;
				})()
			);
			// console.log로 "no error match"가 나온다면, 예외처리 코드를 작성해야 합니다
		},
	},
};
