<template>
	<div class="card pop">
		<div class="header">
			<h1 class="heading-3-b">강제 로그아웃</h1>
			<button class="icon" @click="logout">
				<i class="ic-24 ic-close"></i>
			</button>
		</div>
		<div class="contents-wrap">
			<p class="body-2-r text-center">
				<em class="point">{{ loginId }}</em> 계정으로 다른 PC에서 로그인되어 강제 로그아웃 되었습니다.
			</p>
		</div>
		<div class="btn-area group-btn">
			<button class="solid" @click="logout">확인</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ForceLogout',
	computed: {
		loginId() {
			return this.$store.getters.getLoginId;
		},
	},
	methods: {
		async logout(e) {
			e.preventDefault();
			this.$modal.hide('ForceLogout');
			await this.$store.commit('INITIALIZE_ADD_EXTENSION');
			await this.$store.commit('INITIALIZE_APPLY');
			await this.$store.commit('INITIALIZE_ORDER');
			await this.$store.commit('INITIALIZE_USER');
			await this.$store.commit('INITIALIZE_COMMON');
			await this.$store.commit('INITIALIZE_LOGIN');
			sessionStorage.removeItem('authToken');
			await this.$router.push('/login');
		},
	},
};
</script>

<style scoped>
@import '../../assets/css/common.css';
</style>
